<template>
  <v-main app class="">
    <div class="d-flex flex-column flex-grow-1 h-full">
      <app-header></app-header>
      <v-container class="h-full" :class="{'pt-6': !fullWidthRoute.includes($route.name),
        'zoom-8': zoomRoute.includes($route.name), 'pa-0': $route.name === 'Sound Effect',
        'px-10': reportSubRoute.includes($route.name)}"
        :fluid="fullWidthRoute.includes($route.name) || reportSubRoute.includes($route.name)">
        <!-- If using vue-router -->
        <transition mode="out-in">
          <router-view />
        </transition>
      </v-container>
      <div class="flex-grow-1"></div>
      <app-footer></app-footer>
    </div>
  </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import menuItems from "@/constants/sidebar-menu";

export default {
  name: "LayoutScreen",
  components: {
    AppFooter: () => import("@/layouts/component/Footer.vue"),
    AppHeader: () => import("@/layouts/component/Header.vue"),
  },
  data() {
    return {
      fullWidthRoute: ['Producer', 'On Air', 'Staff', 'Tally', 'Sound Effect'],
      zoomRoute: ['Producer', 'On Air', 'Staff', 'Tally'],
      reportSubRoute:[]
    }
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
  created() {
    const reportRouters = (menuItems.find(item => item.title === 'Reports')).children;
    reportRouters.map(item => {
      if (Array.isArray(item.route) && item.route.length) item.route.map(route => this.reportSubRoute.push(route));
      else this.reportSubRoute.push(item.route);
    })
  },
  mounted() {
      this.wsRoleUserUpdate();
  },
  methods: {
    ...mapActions(["getUserData"]),

    wsRoleUserUpdate() {
      const userId = this.$helpers.getCurData('curUserId');
        Echo.channel(`user.${userId}`).listen('.updated',(data) => {
          const userRole = this.$helpers.getCurData('curUserRole'),
            currUser = this.$helpers.getCurData('curUser');
          currUser['permissions'] = data.permissions;
          currUser['roles'] = data.roles;
          this.$helpers.lsPush("curUser", currUser);
          this.$store.commit('updateAccessURLs', data.permissions);
          if(data.roles.join() !== userRole.join()) this.restrictNavigate(data);
        });
        Echo.channel('role').listen('.updated',(data) => {
          const userRole = this.$helpers.getCurData('curUserRole');
          if (userRole.indexOf(data.name) !== -1) {
            this.getUserData(userId).then(resp => {
              const data = resp.data.data,
                currUser = this.$helpers.getCurData('curUser');
              currUser['permissions'] = data.permissions;
              currUser['roles'] = data.roles;
              this.$helpers.lsPush("curUser", currUser);
              this.$store.commit('updateAccessURLs', data.permissions);
              this.restrictNavigate(data);
            })
          }
        });
    },
    restrictNavigate(data) {
      const currRoute = this.$helpers.getCurrRouteDetails(this.$route.name);
      if (currRoute.access && data.permissions.indexOf(currRoute.access) === -1 && ['Home', 'LP Entry'].indexOf(this.$route.name) !== -1) {
        if(this.getThemeMode.verticalSidebarDrawer) this.$store.commit('toggleVerticalSidebarDrawer');
        this.$store.commit('setLockScreenEnable', true);
        localStorage.setItem('pageLock', true);
      } else if (['Home', 'LP Entry'].indexOf(this.$route.name) === -1) {
        this.$store.commit('setLockScreenEnable', false);
        localStorage.removeItem('pageLock');
        if (this.$route.name !== 'Info') this.$router.push('info');
      }
    }
  },
  beforeDestroy() {
      Echo.leaveChannel('user');
      Echo.leaveChannel('role');
  }
};
</script>